<template>

  <div class="login">
      <div style="text-align: center;margin-bottom: 50px;">
          <h1>实验器材进出登记</h1>
      </div>
      <el-form
        label-width="100px"
        style="max-width: 460px"
      >
        <el-form-item label="姓名：">
          <el-input v-model="username" />
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="password"  type="password"/>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="large" style="margin: 30px 0 0 220px;" @click="login">登录</el-button>
  </div>
</template>

<script>

import { ElMessage } from 'element-plus'
import { JSEncrypt } from 'jsencrypt'
import { Base64 } from 'js-base64'
export default {
    data(){
        return {
            username: "",
            password: "",
        };
    },
    methods:{
        login(){

            let self = this;
            this.$request.doPost('/public/getPublicKey').then(res => {
                if(res.code == 20000)
                {
                    let publicKey = res.data;
                    const encrypt = new JSEncrypt()
                    encrypt.setPublicKey(publicKey)
                    let password = encrypt.encrypt(Base64.encode(self.password))
                    let username = self.username
                    let params = {'username': username, 'password': password, 'publicKey': publicKey}
                    self.$request.doPost('/public/login', params).then(loginRes => {
                        if(loginRes.code == 20000)
                        {
                            self.$store.state.token = loginRes.data
                            self.$router.push({ path:'/aa'})
                        }else{
                            ElMessage.error(loginRes.msg)
                        }
                    })

                }else{
                    ElMessage.error(res.msg)
                }

            })

        },
    },
}
</script>

<style>

.login {width: 500px;height: 500px;background: #778899;margin: 100px auto;border-radius: 10px;padding: 100px 0 0 0px;box-sizing: border-box;}
</style>